@import 'themes';

.clearFilter{
    text-align: right;
    margin-top: -25px;
    margin-bottom: 10px;
    color: #3030ff;
    cursor: pointer;
    font-size: 15px;
    font-family: "TT Commons";
}

.loadmore{
    text-align: center;
    color: $secondary-text;
    cursor: pointer;
    background-color: #E8EEFA;
    border-radius: 51px;
    margin: 16px;
    line-height: 24px;
    font-weight: 400;
    vertical-align: middle;
    padding: 8px 0;
}

.popupActionsMenu {
    ul {
        padding: 0;
    }
}

.popupActions {
    font-family: "Hind" !important;
    font-size: 14px !important;
    color: #05112A !important;
    padding: 12px 18px !important;
    border: none !important;

    &:not(:first-child) {
        border-top: 1px solid #E8EEFA !important;
    }

    svg {
        margin-right: 10px;
        vertical-align: middle;
    }
}
