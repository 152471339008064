@import '../../scss/themes.scss';

.dialogModal {

  .header {
    font-family: TT Commons;
    color: $primary-text;
    padding: 20px 24px 8px;
    text-align: left;

    .title {
      font-family: TT Commons;
      font-weight: 700;
      margin: 0;
      font-size: 30px;
      line-height: 110%;
      color: $primary-text;
    }

    .closeButton {
      position: absolute;
      right: 20px;
      top: 20px;
      color: $primary-text;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    color: $primary-text;

    .divider {
      margin-top: 0;
      background-color: $primary-separator-color;
    }
  }

  .actions {
    margin: 0;
    padding: 0 24px 16px;
    display: flex;
    justify-content: center;

    button {
      width: 96px;
      height: 40px;
      border-radius: 40px;
      font-size: 16px;
      background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
      color: #FEFEFE;
    }
  }
}

.draggableDialogElement {
  cursor: move;
}

.paper {
  width: auto !important;
  min-width: 360px !important;
  font-family: 'Hind';
  position: relative;
  max-height: 90vh;
  max-width: unset !important;
  overflow: hidden !important;

  background-color: $primary-background !important;
  color: $primary-text !important;

  border: 1px solid $primary-separator-color;
  border-radius: 8px !important;

  &.fullScreen {
    border: 1px solid $primary-separator-color;
    @media screen and (max-height: 650px) {
      max-height: 100%;
    }

    @media screen and (max-width: 1050px) {
      width: 100% !important;
      max-width: unset;
      height: 90vh;
    }
  }
}

.resize {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  width: 15px;
  height: 15px;
  cursor: se-resize;
  background-repeat: no-repeat;
}
