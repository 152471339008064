@import '../../scss/themes.scss';

.noPosts {
  display: flex;
  align-items: center;
  flex-direction: column;

  .labelBlock {
    z-index: 1;
    margin-top: 50px;
    width: 220px;
    text-align: center;

    .label, .text {
      margin: 0;
    }

    .label {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: $primary-text;
    }

    .text {
      margin-top: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-text;
    }
  }

  .action {
    z-index: 100;
    margin-top: 25px;
    width: 140px;
    height: 40px;
  }
  
  .image {
    z-index: 1;
    width: 100%;
    position: absolute;
    bottom: 0;
    top: unset;
    
    @media screen and (max-height: 1024px) {
      top: 0;
    }
  }

  .gradient{
    position: absolute;
    top:100px;
    bottom: 0;
    width: 100%;
    z-index: 50;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(247,249,253,0.2) 80%, rgba(247,249,253,0.22) 80%, rgba(247,249,253,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(247,249,253,0.2) 80%,rgba(247,249,253,0.22) 80%,rgba(247,249,253,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(247,249,253,0.2) 80%,rgba(247,249,253,0.22) 80%,rgba(247,249,253,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f7f9fd',GradientType=0 ); /* IE6-9 */
  }
  .background {
    top: 120px;
    bottom: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }
}
