@import '../../scss/themes.scss';

.reply {
  .replyHeader {
    padding-left: 10px;
    padding-top: 8px;

    &.replyHeaderComment {
      padding-top: 5px;
      padding-bottom: 13px;
    }
  }

  .replyContent {
    padding-top: 8px;
    padding-bottom: 0px;
    word-break: break-word;
  }

  .replyActions {
    padding-left: 12px;
  }

  .actionBlock {
    padding: 0;
    margin-left: 10px;

    .iconButton {
      &:hover {
        background-color: inherit
      }
    }

    .iconUp {
      fill: $primary-separator-color;
      &:hover {
        fill: #8ef8ee
      }
    }


    .iconDown {
      fill: $primary-separator-color;
      &:hover {
        fill: #3182ff
      }
    }

    .icon {
      fill: $secondary-icon-color;
    }

    .iconComments {
      fill: $primary-separator-color;
    }
  }

  .content {
    padding: 16px 16px 16px 32px;

    p {
      margin: 0;
      font-family: Hind;
      line-height: 21px;
      font-size: 14px;
      font-weight: 400;
    }

    :global(.mention) {
      font-weight: bold;
      color: $secondary-text;
      padding: 0;
      background-color: unset;

      &::before {
        content: "@";
      }
    }

    blockquote {
      margin: 5px;
      border-left: 4px solid #ccc;
      padding-left: 16px;
    }
  }

  .replyActionsPopup {
    display: none;
  }

  &:hover {
    .replyActionsPopup {
      display: block;
    }
  }
}

