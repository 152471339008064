.infiniteLoader {
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .loader {
    color: #f50057;
    margin: 15px;
  }
}
