@import '../../scss/themes.scss';

.form {
  margin-bottom: 12px;
  flex: 1 1;
  &.formComments {
    margin-top: 0;
  }

  .formPost {
    height: 100%;
    max-height: 544px;
    text-align:left;
    display: flex;
    flex-direction: column;

    &.liteMode {
      margin-bottom: 20px;

      &.videoMode {
        border: 1px solid #ccc;
        padding: 7px;
      }

      :global(.ql-container) {
        height: 150px !important;
      }

      .item {
        .saveButton {
          width: 75px;
          line-height: 1em;
        }
      }
    }

    .item {
      margin-bottom: 24px;

      .backBtn{
        background: none;
        border: none;
        cursor: pointer;
        color: #003057
      }
    }

    .fullHeight {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .withCounter {
      margin-bottom: 0;
    }

    .newPostFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .userAvatar {
        float: left;
        display: flex;
        align-items: center;

        .avatarUserName {
          margin-left: 5px;
          font-size: 14px;
        }
      }

      .saveButtonContainer {
        margin: 0;
      }

    }

    .inlineSaveButtonContainer{
      text-align: right;

      .saveButton{
        border:0 !important;
        background: none !important;
      }
    }

    .postTitleInput {
      width: 100%;
      background: $secondary-background;
      font-size: 14px;

      input, input::placeholder {
        color: $primary-text;
      }

      input::placeholder {
        opacity: 0.42;
      }

      &.hasError input {
        color: $primary-text;
      }
    }

    .videoInput {
      width: 100%;
      height: 25px;
    }

    .videoLabel{
      justify-content: space-between;
      margin: .5rem;
      display: flex;
      height: 60px;
    }

    .anonymousLabel {
      position: absolute;
      right: 0;
      right: 21px;
      bottom: 44px;
    }

    .contentSelect {
      width: 100%;
    }

    .avatar {
      width: 40px;
      height: 40px;
      font-family: 'Hind';
    }
  }

  .inlineEditorContainer {
    width: 100%;
    margin-left: 12px;
  }

  .inlineEditorRow {
    display: flex;
    flex-direction: row;
    position: static;
    margin-bottom: 25px;
    margin-left: 8px;
  }

  .bottomDivider {
    margin: 0 0 24px;
    background-color: $primary-separator-color;
  }

  .errorMessage {
    margin-bottom: 16px;
  }

  .scrollable {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 10px;

    @media screen and (max-height: 650px) {
      max-height: calc(100vh - 204px);
    }
  }
}
