@import '../../scss/themes.scss';

.deletePostModal {
  text-align: center;

  [class*=MuiPaper-root] {
    background: bottom / contain no-repeat url("https://dfyn1fx6xeb39.cloudfront.net/social-application/images/delete-post-bg.png");
    height: 410px;
  }

  [class*=MuiDialogTitle-root] {
    padding: 20px 24px 32px;
  }

  .question {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    color: $primary-text;
    padding-bottom: 2px;
  }

  .clarification {
    font-weight: normal;
    line-height: 24px;
    color: $primary-text;
    margin-bottom: 12px;
  }
}
