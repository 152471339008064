@import '../../scss/themes.scss';

.button {
  border: 0;
  height: 40px;
  font-style: normal;
  padding: 10px 20px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  background: $button-background;
  border-radius: 40px !important;
  color: $button-text !important;
  &:hover {
    color: $button-text !important;
  }

  &:disabled {
    background: $primary-disabled-element-color;
  }
}

.label {
  min-width: auto !important;
}
