@import '../../scss/themes.scss';

.attachments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.attachmentButton {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background: #E8EEFA;
  border-radius: 4px;
  padding: 8px;
  text-decoration: none;

  .attachmentIcon {
    display: inline-block;
    width: 18px;
    height: 18px;
    fill: $attachment-text;
  }

  .name {
    font-family: Hind;
    font-size: 12px;
    font-weight: bold;
    color: $attachment-text;
    margin-left: 8px;
  }

  .progress {
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }

  .deleteButton {
    margin-left: 8px;
  }
}
