.dialog {
  .mathFieldContent {
    .fieldBox{
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      gap: 5px;

      .enterFormula {
        display: grid;
        flex: 1;

        math-field {
          border: 1px solid #ccc;
          max-width: 50vw;

          &::part(virtual-keyboard-toggle) {
            &::after {
              font-size: 12px !important;
              padding: 6px !important;
              content: 'Toggle keyboard';
              left: 0;
              bottom: 120%;
              right: 0;
            }
          }
          
          &::part(menu-toggle) {
            display: none;
          }
        }
      }

      .actions {
        align-self: flex-end;
        display: grid;

        button {
          background: none;
          border: none;
          cursor: pointer;
          padding: 3px;

          &:first-child {
            color: #06c;
          }

          &:last-child {
            color: #05112A;
          }
        }

      }
    }

    .hint {
      padding-bottom: 5px;
      font-family: sans-serif;
      font-size: small;
      color: #06c;

      a:visited {
        color: #06c;
      }
    }
  }
}