body {
    margin: 0;
    padding: 0;
}

#content {
    display: flex;

    #topMarkup {
        cursor: pointer;
        background-image: url('https://dfyn1fx6xeb39.cloudfront.net/social-application/images/drawer.png');
        background-size: cover;
        min-width: 80px;
        height: 100vh;
    }
    
    #iframe {
        width: 100vw;
        height: 100vh;
    }
}

#studyContent {
    width: 1480px;
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: #071324;
    min-height:2000px;

    #AssetPageContainer {
        width: 100%;
    }

    #studySocial {

    }
}


#userid {
    position: fixed;
    top: 30px;
    right: 20px;
    color: rgb(0, 0, 0);
}

#theme {
    position: fixed;
    top: 60px;
    right: 20px;
    color: rgb(0, 0, 0);
}

#social {
    z-index: 1000;

    & > div:first-child {
        height: 100vh;
        width: 400px;
        background: #f6f9fd;
        position: relative;
        left: 0;
        top: 0;
        box-shadow: 1px 3px 10px rgba(213, 219, 232, 0.64);
        border-radius: 16px;

        @media screen and (max-width: 1050px) {
            width: 100%;
            max-width: 1050px;
        }
    }

    .closeSideBar {
        position: absolute;
        top: 30px;
        right: 25px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        background-size: contain;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEOSURBVHgBldQ7jsIwEAbgf2xFm0WLFGmr3Sol5e4JCB0lJbfjCHADcgMoKVNSULiCIARmBiUSAttxporsmS8jvzSyPNPp79ym3wb1waBPPGp/pjb92iuNz0IRFgmpNbJRHo+M8oQGG0W01BjM1BUfJWArnsqjsQfCuVwjtQlupYLZGm3tJBp7QaS2NruK2vmUE65EnECCVBd7m4ATYhCZoue8IBZA3iAvxhFCnJALa4a9iBdyYAghEsoH1W//oWbMHe6OXha2SeVvz246IcfuyHDX0aAupF2TrnNGMUgbIYxikS6M+iB+7PjPz4gq+iASkvN80fkZGXNHf5nGuRjiVBpT9XrYpLMLNzLEcXUH/gnpWGT8nlkAAAAASUVORK5CYII=')
    }

    .postsTitle {
        font-size: 40px;
        font-weight: 500;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #333333;
        margin-bottom: 20px;
        height: 40px;
        padding: 20px 0 0 20px;
        font-family: "TT Commons";
    }
}
