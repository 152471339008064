@import '../../scss/themes.scss';

.postsFilter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;

  .dropdown {
    flex-grow: 1;
  }
}

.filterSortIcon{
  background: $secondary-background !important;
  border: 1px solid $primary-separator-color !important;
  width: 40px !important;
  height: 40px !important;
  margin-right: 5px !important;

  &:hover {
    border-color: #000000 !important;
  }
}

.icon {
  fill: $primary-icon-color;
}

.popover {
  background: $secondary-background !important;
  color: $primary-text !important;

  [class*="Mui-selected"], .menuItem:hover {
    background-color: $primary-active-color !important;
  }
}
