@import 'themes.scss';


.contentSelect {
    width: 100%;
    background-color: $primary-background;
}

.attachmentBlock {
    margin-bottom: 24px;
}

.attachmentList {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}
