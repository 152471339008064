.ql-snow .ql-tooltip[data-mode]::before {
  content: none;
}

.ql-tooltip-label {
  padding-right: 5px;
  display: none;
}

.ql-tooltip[data-mode=link] {
  .ql-tooltip-label-link {
    display: block;
  }
}

.ql-tooltip[data-mode=video] {
  .ql-tooltip-label-video {
    display: block;
  }
}

.ql-tooltip[data-mode=formula] {
  .ql-tooltip-label-formula {
    display: block;
  }
}
