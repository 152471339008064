@import '../../scss/themes.scss';

.dialog {
  .container {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    background: $primary-background;
    border: 1px solid $primary-separator-color;
  }

  .messageBlock {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }

  .title {
    font-family: TT Commons;
    font-size: 28px;
    line-height: 110%;
    color: $primary-text;
    margin: 0;
  }

  .subtitle {
    font-family: Hind;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    color: $primary-text;
    margin-top: 8px;
  }

  .actionBar {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  .cancelButton,
  .confirmButton {
    padding: 4px 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    border-radius: 40px;
    width: 110px;

    > span {
      min-width: unset;
    }
  }

  .cancelButton {
    color: $primary-text;
    background: 'transparent';
    border: 1px solid #E8EEFA;

    &:hover {
        color: $primary-text;
    }
  }

  .confirmButton {
    margin-left: 12px;
    color: $button-text;
    background: $button-background;
    border: 1px solid transparent;

    &:hover {
        color: $button-text;
    }
  }

  .communityGuidelinesLink {
    color: #8d44ad;
  }
}
