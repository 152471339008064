@import '../../scss/themes.scss';

.inputHint {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  margin-top: 1px;
  color: $primary-hint-color;

  &.isError {
    .primaryHint {
      color: #DD4A0B;
    }
  }

  .primaryHint {
    flex-grow: 1;
    text-align: left;

    > * {
      vertical-align: middle;
    }

    & > svg {
      font-size: 24px;
    }
  }

  .secondaryHint {
    text-align: right;
  }

  .label {
    margin-left: 4px;
    font-size: 19px;
  }
}
