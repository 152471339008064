@import '../../scss/themes';

@mixin truncated($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.postContentWrapper {
  padding: 0px 20px 8px 20px !important;
  word-break: break-word;
}

.postContent {
  &.postContentComment {
    padding: 0px 20px 12px 10px !important;
    img {
      max-width: 400px !important;
      max-height: 200px !important;
    }
  }
  .title {
    margin: 0 0 4px;
    color: $primary-text;
    font-size: 16px;
  }

  :global(.ql-inline-link) {
    color: #06c;
  }

  .withBadge {
    text-indent: 60px;
  }

  p {
    margin: 0;
  }

  img {
    max-width: 100% !important;
    max-height: 100% !important;
    cursor: pointer;
    &[src*='giphy.com'] {
      cursor: initial;
    }
  }

  .postText {
    color: $primary-text;
    word-break: break-word;

    &.postTextComment {
      font-size: 14px;
      font-weight: 400;
    }

    blockquote {
      margin: 5px;
      border-left: 4px solid #ccc;
      padding-left: 16px;
    }
  }

  .quotationBlock {
    margin: 0 0 16px;
  }

  :global(.mention) {
    font-weight: bold;
    color: $secondary-text;
    padding: 0;
    background-color: unset;

    &::before {
      content: '@';
    }
  }

  .viewMode {
    @include truncated(5);
  }

  .showButton {
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    display: block;
    margin: 5px 0 0 auto;
    color: #5384cd;
  }

  .showButtonLabel {
    min-width: auto;
  }

  .attachmentsBlock {
    margin-top: 15px;
  }
}
