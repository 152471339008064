@import '../../scss/themes.scss';

.postsError {
  text-align: center;

  .image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  button {
    z-index: 1;
  }
  .labelBlock {
    margin: 48px 0;
    .label {
      z-index: 1;
      font-family: Hind;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: $primary-text;
    }
  }
}
