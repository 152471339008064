@import '../../scss/themes.scss';

.threeDotsMenu{
  padding:0px !important;
  color: $primary-text !important;
  font-size: 21px !important;
}

.popupActionsMenu > ul {
  padding: 0;
}

.popupActionItem {
  font-family: Hind !important;
  font-size: 14px !important;
  color: $primary-text !important;
  padding: 12px 18px !important;
  border: none !important;

  &:not(:first-child) {
    border-top: 1px solid $primary-separator-color !important;
  }

  svg {
    margin-right: 10px;
    vertical-align: middle;
  }
}

.popover {
  background: $secondary-background !important;
  color: $primary-text !important;
}