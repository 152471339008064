@import '../../scss/themes.scss';

.questionEditor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  :global(.ql-preview){
    transition: all .5s linear;
  }
  :global(.ql-preview) {
      max-width: 95px !important;
    }
  :global(.ql-toolbar) {
    background-color: $secondary-background;
    color: $primary-text;
    border-color: var(--input-border-color);
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    :global(.ql-formats) {
      display: flex;
      flex-wrap: wrap;
      gap: 17px;
      margin: 0;
      padding: 0;
      list-style: none;

      button {
        padding: 0;
        width: 18px;
        height: 18px;

        svg {
          fill: $primary-text;
        }

        &:hover {
          svg {
            fill: #06c;
          }
        }
      }

      :global(.ql-active){
        svg {
          fill: #06c;
        }

        :global(.ql-fill) {
          stroke: #06c;
        }
      }
    }
  }

  :global(.quill) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  :global(.ql-container) {
    flex-grow: 1;

    font-family: 'Hind';
    font-size: 16px;
    border: none;

    background-color: $secondary-background;
    color: $primary-text;

    height: calc(40vh - 200px);
    min-height: 70px;
    @media screen and (max-width: 1049px) {
      height: calc(100% - 210px);
    }
  }

  :global(.ql-editor) {
    border: 1px solid var(--input-border-color);
    border-radius: 0 0 4px 4px;

    :global(.ql-inline-image) {
      max-width: clamp(0px, 100%, 400px);
      max-height: 200px;
    }

    &:hover {
      border-color: #252525;
    }

    &:before {
      font-style: normal;
      color: #6A707C;
    }

    &:focus-visible {
      border-color: #005d83;
    }

    :global(.mention) {
      font-weight: bold;
      color: $secondary-text;
      padding: 0;
      background-color: unset;
    }
  }

  .toolbarActionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-wrap: wrap;
    .saveButton {
        border: 1px solid $primary-text;
        padding: 5px 10px;
        color: $primary-text;
        border-radius:5px;
        opacity: 0.8;
        margin-left:10px;

        &:hover {
            opacity: 1;
        }
    }

    .cancelButton {
      @extend .saveButton;
      border: none;
    }
  }

  .inlineToolbar {
    background-color: transparent;
    border: none !important;
    opacity: 0.8;
    padding: 12px;
    :global(.ql-formats) {
      button {
        svg {
          fill: $primary-text;
        }

        :global(.ql-fill) {
          stroke: $primary-text;
        }
      }
    }
  }

  .noText {
    :global(.ql-container) {

      :global(.ql-editor) {

        p {
          min-height: 50px;
        }
      }
    }
  }

  .inlineQuill {
    background-color: transparent !important;
    max-height: 400px;
    overflow-y: auto;
    flex-grow: initial;

    :global(.ql-container) {
      background-color: transparent !important;
      min-height: 45px !important;
      color: $primary-text !important;
      height: auto;

      :global(.ql-editor) {
        color: $primary-text !important;
        border-style: none none solid none;
        border-radius:0 !important;
        border-color: $primary-text !important;
        min-height: max-content;
        padding-bottom: 5px;
        &:before {
          font-family: 'TT Commons' !important;
          font-size: 18px !important;
          line-height: 24px !important;
          letter-spacing: 0.24px !important;
          color: $placeholder-text !important;
        }
      }
    }
  }
}

.inputPlaceholder {
  background-color: transparent;
  font-family: Hind;
  border: none;
  border-bottom: 1px solid $primary-text;
  width: 100%;
  padding: 10px;
  color: #989BA3;
  font-size: 16px;
  text-align: start;
}

.editorHint {
  color: #989BA3;
  font-size: 16px;
  font-family: 'TT Commons', sans-serif;
}
