.MuiCard-root {
  padding: 20px;

  .MuiAvatar-root, .MuiCardHeader-avatar, .MuiCardHeader-subheader , .MuiTypography-subtitle1 {
    font-family: "TT Commons";
  }

  .MuiCardHeader-root {
    padding-bottom: 5px;
    padding-top: 0px;

    .MuiCardHeader-title {
      display: inline-block;
    }
  }
}

.MuiCard-root {
  .MuiCardHeader-subheader
  {
    position: relative;
    top: -5px;
    font-size: 13px;
  }
}
