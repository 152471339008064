@import '../../scss/themes.scss';

.formFieldLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  &.error {
    .label, .hint {
      color: #DA3100;
    }
  }

  > .label {
    font-weight: 600;
    line-height: 150%;
    color: $primary-text;
  }

  > .hint {
    font-family: Hind;
    font-weight: normal;
    line-height: 150%;
    margin-left: 8px;
    color: #4F5561;
  }
}
