@import '../../scss/themes.scss';

.postView {
  background-color: $primary-background;
  position: relative;
  font-family: 'Hind';
  user-select: text;

  .showPreviewLevelLink {
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    .allPosts {
      text-indent: 8px;
      font-family: Hind;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $primary-text;
    }

    .allPostIcon {
      margin-bottom: 4px;
      fill: $primary-text;
    }
  }

  .linkTitle {
    font-family: 'TT Commons';
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    color: $primary-text;
  }

  .postMenu {
    display:flex;
    align-items: center;
    padding: 20px 20px 0;
  }

  .postMenuWithNewPost{
    display: block !important;
  }

  .divider {
    margin: 20px;
    background-color: $primary-separator-color;
  }

  .loadMore {
    text-align: center;
    color: $secondary-text;
    cursor: pointer;
    background-color: #E8EEFA;
    border-radius: 51px;
    margin: 16px;
    line-height: 24px;
    font-weight: 400;
    vertical-align: middle;
    padding: 8px 0;
  }

  .posts {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
    position:relative;
    height: calc(100vh - 215px);
  }

  .rtePannel {
    margin-left: 8px;
  }
}

.loadMoreButton {
  color: #5384CD;
  background-color: $secondary-background;
  margin-bottom: 20px;
  padding: 8px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $primary-separator-color;
  cursor: pointer;
  line-height: 24px;
  font-weight: 400;
  font-size: inherit;
}

.noPosts {
  margin: 20px 0 28px;
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  width: 100%;
  text-align: center;
  color: $primary-text;
}
