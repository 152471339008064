$themes: (
    1-default: (
        'button-text': #F7F9FD,
        'button-background': linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%),
        'quotation-block-background': #FEFEFE,
        'quotation-block-text': #333333,
        'attachment-text': rgb(51, 51, 51),
        'card-shadow': 0px 12px 17px 2px rgba(0, 0, 0, 0.1),

        'primary-text': #05112A,
        'secondary-text': #333333,
        'placeholder-text': rgba(#333333, .75),

        'primary-background':  #F6F9FD,
        'secondary-background':  #FEFEFE,
        'primary-separator-color': #E8EEFA,

        'primary-icon-color': #003057,
        'secondary-icon-color': #6A707C,

        'primary-hint-color': #4F5561,
        'primary-active-color': rgba(0,0,0,0.08),

        'primary-disabled-element-color': rgb(204,204,204),
        'input-border-color': #707D89
    ),
    1-dark: (
        'button-text': #F7F9FD,
        'button-background': linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%),
        'quotation-block-background': '#F6F9FD',
        'quotation-block-text': #333333,
        'attachment-text': rgb(51, 51, 51),
        'card-shadow': 0px 12px 17px 2px rgba(255, 255, 255, 0.1),

        'primary-text': rgb(254, 254, 254),
        'secondary-text': rgb(254, 254, 254),
        'placeholder-text': #989BA3,

        'primary-background':  rgb(16, 16, 16),
        'secondary-background':  rgb(28, 28, 30),
        'primary-separator-color': rgb(51, 51, 51),

        'primary-icon-color': white,
        'secondary-icon-color': white,

        'primary-hint-color': rgb(254, 254, 254),
        'primary-active-color': rgba(100, 100, 100, 0.3),

        'primary-disabled-element-color': rgb(204,204,204),
        'input-border-color': #E8EEFA
    ),
    1-sepia: (
        'button-text': #F7F9FD,
        'button-background': linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%),
        'quotation-block-background': '#F6F9FD',
        'quotation-block-text': #2a1b05,
        'attachment-text': rgb(51, 51, 51),
        'card-shadow': 0px 12px 17px 2px rgba(0, 0, 0, 0.1),

        'primary-text': #2a1b05,
        'secondary-text': #2a1b05,
        'placeholder-text': rgba(#2a1b05, .75),

        'primary-background':  #f7f4e7,
        'secondary-background':  rgb(252, 250, 237),
        'primary-separator-color': rgba(155, 161, 173, 0.5),

        'primary-icon-color': #2a1b05,
        'secondary-icon-color': #2a1b05,

        'primary-hint-color': #4F5561,
        'primary-active-color': rgba(0,0,0,0.08),

        'primary-disabled-element-color': rgb(204,204,204),
        'input-border-color': #4F5561
    ),
    2-dark: (
        'button-text': #F7F9FD,
        'button-background': linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%),
        'quotation-block-background': '#F6F9FD',

        'primary-text': #F7F9FD,
        'secondary-text': #F7F9FD,
        'placeholder-text': #989BA3,

        'primary-background':  #05112A,
        'secondary-background':  #071324,
        'primary-separator-color': #4F5561,

        'primary-icon-color': #F7F9FD,
        'secondary-icon-color': #F7F9FD,

        'primary-hint-color': #F7F9FD,
        'primary-active-color': rgba(100, 100, 100, 0.3),

        'primary-disabled-element-color': rgb(204,204,204),
    ),
);

/* variable names */
$button-text: var(--button-text, #F7F9FD);
$button-background: var(--button-background, linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%),);

$quotation-block-background: var(--quotation-block-background, #FEFEFE);
$quotation-block-text: var(--quotation-block-text, #333333);
$attachment-text: var(--attachment-text, rgb(51, 51, 51));
$card-shadow: var(--card-shadow, white);

$primary-text: var(--primary-text, #05112A);
$secondary-text: var(--secondary-text, #333333);
$placeholder-text: var(--placeholder-text, #333333);

$primary-background: var(--primary-background, #F6F9FD);
$secondary-background: var(--secondary-background, #FEFEFE);
$primary-separator-color: var(--primary-separator-color, #E8EEFA);

$primary-icon-color: var(--primary-icon-color, #003057);
$secondary-icon-color: var(--secondary-icon-color, #6A707C);

$primary-hint-color: var(--primary-hint-color, #4F5561);
$primary-active-color: var(--primary-active-color, rgba(0,0,0,0.08));

$primary-disabled-element-color: var(--primary-disabled-element-color, rgb(204,204,204));

/* function to generate variables */
@each $name, $map in $themes {
    .social-theme-#{$name} {
        @each $key, $value in $map {
            --#{$key}: #{$value};
        }
    }
}