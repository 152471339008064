a.ql-video {
  display: inline-block !important;
  position: relative;

  &:not(.error)::after {
    content: "";
    position: absolute;
    background: center / 30% no-repeat url("../assets/images/play-button.svg");
    background-color: #00000077;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.error {
    color: red;
    font-weight: bold;
    text-decoration: none;
  }

  .ql-video-thumbnail {
    width: 300px;
    height: 225px;
  }
}

.ql-snow .ql-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin-top: 0 !important;
  display: flex;
  align-items: baseline;
  border: 1px solid #2D374A !important;

  input[type=text] {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}

.attachmentobject {
  svg {
    width: 18px;
    height: 18px;
  }
}