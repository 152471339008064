@import '../../scss/themes.scss';

.genericErrorModal {
  .content {
    margin: 40px 0;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
    color: $primary-text;
  }
  .buttons {
    display: flex;
    margin-bottom: 25px;
    gap: 20px;
  }
}
