@import '../../scss/themes.scss';

.quotationBlock {
  background: #f6f9fd;
  padding: 8px 12px;
  border-left: 3px solid #9D9D9D;
  border-radius: 0 5px 5px 0;
  margin-top: 8px;

  .quotation {
    display: flex;
    font-family: Palatino;
    font-size: 12px;
    text-align: start;
    color: $quotation-block-text;
    line-height: 24px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
  }

  .quotation::before, .quotation::after {
    content: '"';
  }

  .linkName {
    display: block;
    font-family: Hind;
    font-style: italic;
    font-size: 12px;
    text-align: start;
    color: $quotation-block-text;
    line-height: 18px;
    margin: 2px 0 0;
    padding: 0;
    border: none;
    background: none;

    :not(:first-of-type) {
      margin-top: 2px;
    }
  }


  .truncated {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .truncatedLinkName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.clickable {
  cursor: pointer;
}