@import '../../scss/themes.scss';

.expandButton {
  position: relative;
  padding: 9px 18.5px 9px 14px;
  display: inline-flex;
  height: 40px;
  text-wrap: nowrap;
  text-overflow: ellipsis;

  font-size: 16px;
  font-family: Hind;
  font-weight: 400;
  line-height: 20px;

  color: $primary-text;
  background-color: $secondary-background;

  border: 1px solid $primary-separator-color;
  border-radius: 4px;

  min-width: 0;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    border-color: #000000;
  }

  &:disabled {
    color: #c7c7c7;
    cursor: default;
    border-color: rgba(0, 0, 0, 0.63);
  }

  .icon {
    top: calc(50% - 12px);
    color: $primary-text;
    opacity: 0.54;
    right: 5px;
    position: absolute;
    pointer-events: none;
  }
}
/*
top: -2px;
    left: -3px;
    width: calc(100% + 1px);
    border: 2px solid #1977D4;
    height: calc(100% + 1px);
    content: "";
    position: absolute;
    box-sizing: content-box;
    border-radius: 36px;
*/
.menuItem {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  background-color: $secondary-background !important;

  > button {
    padding: 6px 0px;

    &:hover {
      background: none;
    }

    &:after {
      border-radius: 7px !important;
      top: 0px !important;
      left: -11px !important;
      width: calc(100% + 15px) !important;
      height: calc(100% + -4px) !important;
    }
  }

  &:hover {
    background-color: $primary-active-color !important;
  }

  .menuItemIconWrapper {
    min-width: 30px;
    margin-right: 5px;
  }

  .menuItemIcon {
    color: $primary-text !important;

    &:after {
      top: -2px !important;
      left: -3px !important;
      width: calc(100% + 1px) !important;
      height: calc(100% + 1px) !important;
    }
  }

  .withoutIcon {
    padding-left: 35px;
  }
}

.isSelected {
  background-color: $primary-active-color !important;
}

.valueText {
  overflow: hidden;
  text-overflow: ellipsis;
  color: $primary-text !important;
  font-weight: normal;
  display: block !important;
}

.list {
  min-width: 360px !important;
  background-color: $secondary-background !important;
}
