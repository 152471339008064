@import '../../scss/themes.scss';

.newReply {
  .postCard {
    margin-bottom: 16px;
    background-color: $secondary-background !important;
    color: $primary-text !important;
    font-family: Hind;
    box-shadow: none !important;
    border: 1px solid $primary-separator-color;

    .truncated {
      -webkit-line-clamp: 5 !important;
    }

    .buttonRoot {
      [class*="MuiSvgIcon-root"] {
        font-size: 21px;
      }
    }
  }

  .caption {
    font-weight: 600;
    line-height: 24px;
  }

  .booked {
    font-size: 21px;
  }
}
