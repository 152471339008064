@import '../../../scss/themes.scss';

.dialog {
  width: auto !important;

  .gridWrapper {
    height: 400px;
    overflow-y: auto;
  }

  .gifFieldContent {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0;
    background: $primary-background;

    .searchContainer {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .gifSearchBar {
        flex-grow: 1;
        font-size: 16px;
        line-height: 24px;
        background: none;
        margin-right: 15px;

        input {
          height: 44px;
          border: 1px solid $primary-separator-color;
          border-radius: 40px;
          padding: 0 16px;
          color: $primary-text;
          background: $secondary-background;
          &::placeholder {
            color: $secondary-text;
            opacity: 0.8;
          }
          &:hover {
            border-color: $primary-text;
          }
        }
        :not(input) {
          display: none;
        }
      }
    }
    .closeButton {
      margin-left: auto;
      color: $primary-text;
    }
  }
  .divider {
    margin-bottom: 24px;
    margin-top: 0;
    background-color: $primary-separator-color;
  }
  .poweredBy {
    text-align: right;
    padding: 20px 0;
    img {
      width: 100px;
    }
  }
}
