@import '../../scss/themes';


.filter {
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  margin: 0 0 5px 0 !important;

  [class*="MuiOutlinedInput-notchedOutline"] {
    border-color: transparent;
  }

  .select {
    padding: 6px 28px 6px 9px;
    color: $secondary-icon-color;
    font-family: Hind;
    font-size: 14px;
  }

  .filterIcon {
    width: 18px;
    height: 16px;
    cursor: pointer;
    fill: $secondary-icon-color;
    position: absolute;
    top: 8px;
    transform: none;
  }
}

.menu {
  .menuItem {
    color: $primary-text;
    padding: 6px 9px;
    font-family: Hind;
    font-size: 14px;
    line-height: 14px;
  }

  [class*="Mui-selected"], .menuItem:hover {
    background-color: $primary-active-color !important;
  }

  [class*="MuiList-root"] {
    background-color: $secondary-background;
  }
}