@import '../../scss/themes.scss';

.accordion {
  .header {
    background-color: $primary-background;
    border: none;
    margin-bottom: 16px;
    text-align: left;
    color: $primary-text;
    cursor: pointer;

    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .headerDisabled {
    color: #6A707C;
    cursor: default;
  }

  .arrowIcon {
    float: left;
    margin-top: 1px;
  }

  .label {
    font-family: 'TT Commons';
    font-weight: 500;
    font-size: 18px;
    margin: 0 0 0 8px;
    display: flex;
  }
}
